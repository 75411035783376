'use strict';

(function() {
  function MapService($http, appConfig) {
    return {
      getGooglePlaceFromText(address) {
        return $http({
          url: '/api/v1/helpers/google-map-search',
          method: "GET",
          params: {
            address: address
          }
       });
      },
      getGooglePlaceFromLatLng(latlng) {
        return $http({
          url: '/api/v1/helpers/google-map-reverse-geocode',
          method: "GET",
          params: {
            latlng: latlng
          }
       });
      }
    }
  }

  angular.module('windmanagerApp')
    .factory('MapService', MapService);

})();
